import React from "react";
import TopBar from "./header/TopBar.js";
import ProductBar from "./header/ProductBar.js";

function Header() {
  return (
    <header>
        <TopBar />
        <ProductBar /> 
    </header>
  );
}

export default Header;
