import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged,signOut } from "firebase/auth";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../commons/FetchConfig";

const LogoutButton = () => {
  const config = getConfig(); // Get the configuration object
  const {
    firebaseApiKey,
    firebaseAuthDomain,
    firebaseProjectId,
    firebaseStorageBucket,
    firebaseMessagingSenderId,
    firebaseAppId,
    firebaseMeasurementId,
  } = config;

  // Initialize Firebase app
  const firebaseApp = initializeApp({
    apiKey: firebaseApiKey,
    authDomain: firebaseAuthDomain,
    projectId: firebaseProjectId,
    storageBucket: firebaseStorageBucket,
    messagingSenderId: firebaseMessagingSenderId,
    appId: firebaseAppId,
    measurementId: firebaseMeasurementId,
  });

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const auth = getAuth(firebaseApp);
  const { isAuthenticated: isAuth0Authenticated } = useAuth0();
  const { logout } = useAuth0();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, [auth]);

  const handleLogout = async () => {
    try {
      setLoading(true);
      // Sign out the user
      if (user) {
        await signOut(auth);
      } else if (isAuth0Authenticated) {
        await logout();
      }
      console.log("Logged out");
    } catch (error) {
      console.error("Error logging out:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {user && !isAuth0Authenticated ? (
        <div>
          <button className="header-product-nav-button" onClick={handleLogout} disabled={loading}>
            {loading ? "Logging out..." : "Logout"}
          </button>
        </div>
      ) : !user && isAuth0Authenticated ? (
        <div>
          <button className="header-product-nav-button" onClick={handleLogout} disabled={loading}>
            {loading ? "Logging out..." : "Logout"}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default LogoutButton;
