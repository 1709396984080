import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInAnonymously,
  
  onAuthStateChanged
} from 'firebase/auth';
import { useAuth0 } from '@auth0/auth0-react';
import { getConfig } from '../commons/FetchConfig';

const LoginAnonButton = () => {
  const config = getConfig(); // Get the configuration object
  const {
    firebaseApiKey,
    firebaseAuthDomain,
    firebaseProjectId,
    firebaseStorageBucket,
    firebaseMessagingSenderId,
    firebaseAppId,
    firebaseMeasurementId
  } = config;

  // Initialize Firebase app
  const firebaseApp = initializeApp({
    apiKey: firebaseApiKey,
    authDomain: firebaseAuthDomain,
    projectId: firebaseProjectId,
    storageBucket: firebaseStorageBucket,
    messagingSenderId: firebaseMessagingSenderId,
    appId: firebaseAppId,
    measurementId: firebaseMeasurementId
  });

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const auth = getAuth(firebaseApp);
  const { isAuthenticated: isAuth0Authenticated } = useAuth0();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, [auth]);

  const handleAnonLogin = async () => {
    try {
      setLoading(true);
      // Sign in anonymously with Firebase
      await signInAnonymously(auth);
      console.log('Logged in anonymously');
    } catch (error) {
      console.error('Error logging in anonymously:', error.message);
    } finally {
      setLoading(false);
    }
  };

  

  return (
    <div>
      {!user && !isAuth0Authenticated ? (
        <button className='header-product-nav-button' onClick={handleAnonLogin} disabled={loading}>
          {loading ? 'Logging in...' : 'Continue as a Guest'}
        </button>
      ) : null}
    </div>
  );
};

export default LoginAnonButton;
