import React from "react";
import Internet from "./ProductCatalogType/Internet";
import Bundle from "./ProductCatalogType/Bundle";
import TV from "./ProductCatalogType/TV";

function ProductCatalog({ productType }) {
  let content;

  switch (productType) {
    case "Internet":
      content = <Internet />;
      break;
    case "TV":
      content = <TV />;
      break;
    case "Bundle":
      content = <Bundle />;
      break;
    default:
      content = null; // Handle the default case if needed
  }

  return content;
}

export default ProductCatalog;
