import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../commons/FetchConfig";

const Profile = () => {
  const { user: userAuth0, isAuthenticated } = useAuth0();

  // Firebase
  const config = getConfig(); // Get the configuration object
  const {
    firebaseApiKey,
    firebaseAuthDomain,
    firebaseProjectId,
    firebaseStorageBucket,
    firebaseMessagingSenderId,
    firebaseAppId,
    firebaseMeasurementId,
  } = config;

  // Initialize Firebase app
  const firebaseApp = initializeApp({
    apiKey: firebaseApiKey,
    authDomain: firebaseAuthDomain,
    projectId: firebaseProjectId,
    storageBucket: firebaseStorageBucket,
    messagingSenderId: firebaseMessagingSenderId,
    appId: firebaseAppId,
    measurementId: firebaseMeasurementId,
  });

  const [user, setUser] = useState(null);
  const auth = getAuth(firebaseApp);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, [auth]);

  return isAuthenticated ? (
    <article>- Hello, {userAuth0?.name} - </article>
  ) : user ? (
    <article>- Hello, {"" + user?.uid || "Guest"} - </article>
  ) : null;
};

export default Profile;
