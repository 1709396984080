import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { fetchConfig } from "./commons/FetchConfig";

const renderApp = async () => {
  try {
    //Retrieving parameters
    const { domain, clientId } = await fetchConfig();
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
      <React.StrictMode>
        <Auth0Provider
          domain={domain}
          clientId={clientId}
          authorizationParams={{ redirect_uri: window.location.origin }}
        >
          <App />
        </Auth0Provider>
      </React.StrictMode>
    );
  } catch (error) {
    console.error("Error rendering app:", error);
  }
};

renderApp();
