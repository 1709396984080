import Header from "./modules/Header";
import Content from "./modules/Content";
import Footer from "./modules/Footer";

function App() {
  return (
    <main>
      <Header />
      <Content />
      <Footer />
    </main>
  );
}

export default App;
