import React, { useState } from "react";
import Content from "../Content";

const ProductBar = () => {
  const [selectedOption, setSelectedOption] = useState("Home");

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="header-productbar-body">
      <nav className="header-productbar-nav">
        <button
          className={`header-productbar-nav-button ${
            selectedOption === "Bundle" ? "active" : ""
          }`}
          onClick={() => handleOptionClick("Bundle")}
        >
          Bundle
        </button>
        <button
          className={`header-productbar-nav-button ${
            selectedOption === "Internet" ? "active" : ""
          }`}
          onClick={() => handleOptionClick("Internet")}
        >
          Internet
        </button>
        <button
          className={`header-productbar-nav-button ${
            selectedOption === "TV" ? "active" : ""
          }`}
          onClick={() => handleOptionClick("TV")}
        >
          TV
        </button>
      </nav>

      {/* Use Content component for rendering content */}
      <Content selectedOption={selectedOption} />
    </div>
  );
};

export default ProductBar;
