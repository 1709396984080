import React from "react";
import ProductCatalog from "./content/ProductCatalog";

const Content = ({ selectedOption }) => {
  return (
    <main>
      <ProductCatalog productType={selectedOption} />
    </main>
  );
};

export default Content;
