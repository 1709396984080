import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';  // Import initializeApp
import { getConfig } from '../commons/FetchConfig';

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated: isAuth0Authenticated } = useAuth0();
  const config = getConfig();
  
  // Initialize Firebase app
  const firebaseApp = initializeApp({
    apiKey: config.firebaseApiKey,
    authDomain: config.firebaseAuthDomain,
    projectId: config.firebaseProjectId,
    storageBucket: config.firebaseStorageBucket,
    messagingSenderId: config.firebaseMessagingSenderId,
    appId: config.firebaseAppId,
    measurementId: config.firebaseMeasurementId
  });

  const firebaseAuth = getAuth(firebaseApp);
  const [isFirebaseAuthenticated, setFirebaseAuthenticated] = React.useState(false);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, user => {
      // Update the Firebase authentication state
      setFirebaseAuthenticated(!!user);
    });

    return () => unsubscribe();
  }, [firebaseAuth]);

  // Render the button only if the user is not authenticated with Auth0 or Firebase
  const shouldRenderButton = !isAuth0Authenticated && !isFirebaseAuthenticated;

  return shouldRenderButton && (
    <button className='header-product-nav-button' onClick={() => loginWithRedirect()}>
      Sign In
    </button>
  );
};

export default LoginButton;
