// config.js

let configData = null;

export const fetchConfig = async () => {
    try {
        const response = await fetch('https://t.candia.us/settings');
        const data = await response.json();
        configData = data;

        return getConfig();
    } catch (error) {
        console.error('Error fetching configuration:', error);
        throw error;
    }
};

export const getConfig = () => {
    if (!configData) {
        throw new Error('Configuration not loaded. Call fetchConfig first.');
    }

    const {
        REACT_APP_AUTH0_DOMAIN,
        REACT_APP_AUTH0_CLIENT_ID,
        ACCESS_CONTROL_ALLOW_ORIGIN,
        FIREBASE_APIKEY,
        FIREBASE_APPID,
        FIREBASE_AUTH_DOMAIN,
        FIREBASE_MEASUREMENTID,
        FIREBASE_MESSAGE_SENDERID,
        FIREBASE_PROJECTID,
        FIREBASE_STORAGE_BUCKET,
        SETTINGS_APP_CACHE_CONTROL
    } = configData;

    return {
        domain: REACT_APP_AUTH0_DOMAIN,
        clientId: REACT_APP_AUTH0_CLIENT_ID,
        accessControlAllowOrigin: ACCESS_CONTROL_ALLOW_ORIGIN,
        firebaseApiKey: FIREBASE_APIKEY,
        firebaseAppId: FIREBASE_APPID,
        firebaseAuthDomain: FIREBASE_AUTH_DOMAIN,
        firebaseMeasurementId: FIREBASE_MEASUREMENTID,
        firebaseMessageSenderId: FIREBASE_MESSAGE_SENDERID,
        firebaseProjectId: FIREBASE_PROJECTID,
        firebaseStorageBucket: FIREBASE_STORAGE_BUCKET,
        settingsAppCacheControl: SETTINGS_APP_CACHE_CONTROL
    };
};
