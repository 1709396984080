import React, { useEffect } from "react";
import LoginButton from "../../components/LoginButton";
import LogoutButton from "../../components/LogoutButton";
import LoginAnonButton from "../../components/LoginAnonButton";
import Profile from "../../components/Profile";

const TopBar = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `
      window.chatwootSettings = {"position":"right","type":"expanded_bubble","launcherTitle":"Chat with us"};
      (function(d,t) {
        var BASE_URL="https://cw.candia.us";
        var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
        g.src=BASE_URL+"/packs/js/sdk.js";
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g,s);
        g.onload=function(){
          window.chatwootSDK.run({
            websiteToken: 'rewMGjnYJBsnjfHhEBCvJRXc',
            baseUrl: BASE_URL
          })
        }
      })(document,"script");
    `;
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
       document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="header-topbar">
      <table className="header-topbar-table">
        <tbody>
          <tr>
            <td className="welcome-cell">
              Welcome to the eCommerce sandbox!!!{" "}
            </td>
            <td>
              <Profile />
            </td>
            <td>
              <LoginButton />
            </td>
            <td>
              <LogoutButton />
            </td>
            <td>
              <LoginAnonButton />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TopBar;
