import React from "react";

const Internet = () => {
  return (
    <div className="content-product-container">
      <div className="content-product">
        <header>
          <h1>Internet Offer </h1>
        </header>
        <section>
          <h2>Product Description</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. ...</p>
        </section>
        <section>
          <h2>Addons Options</h2>
          <ul>
            <li>Addon Option 1</li>
            <li>Addon Option 2</li>
            <li>Addon Option 3</li>
          </ul>
        </section>
        <section>
          <h2>Price</h2>
          <p>Price without Tax: $59.99</p>
          <p>Price with Tax: $69.99</p>
        </section>
        <button className="content-button">Buy Now</button>
      </div>
    </div>
  );
};

export default Internet;
